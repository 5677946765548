import { useCallback, useRef, useState } from 'react'
import {
  WsDuplicateDocumentMessage,
  WsEnhancedResponseMessage,
  WsMessageModel,
} from '@dis/types/src/wsModels'
import { Api } from '@dis/api'
import { useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'

type DataProps = {
  id: string
}

type Props = {
  onData?: (data: DataProps) => void
}

type MessagePart = WsDuplicateDocumentMessage['duplicate']['data'] & {
  journeyId: number
}

export const useDuplicateDocument = (props?: Props) => {
  const [data, setData] = useState<DataProps>()
  const [loading, setLoading] = useState(true)

  const currentTenantId = useAppSelector(selectSelectedTenantId)

  const onData = props?.onData

  const onMessage = useCallback(
    (newData: WsEnhancedResponseMessage) => {
      const newObject = {
        id: newData?.id || '',
      }

      setData(newObject)
      setLoading(false)

      onData?.(newObject)
    },
    [onData, setData, setLoading],
  )

  const send = useCallback(
    ({ name, journeyId, targetTenantId, targetFolderId, targetAtlasId }: MessagePart) => {
      if (currentTenantId) {
        const message: WsDuplicateDocumentMessage = {
          duplicate: {
            data: {
              name,
              targetAtlasId,
              targetFolderId,
              targetTenantId,
            },
            id: journeyId.toString(),
            model: WsMessageModel.Document,
          },
          tenantId: currentTenantId,
        }

        Api.sendDuplicateDocument(message, onMessage)
      }
    },
    [currentTenantId, onMessage],
  )

  const sendRef = useRef(send)
  sendRef.current = send

  const sendWrapper = useCallback((messagePart: MessagePart) => sendRef.current(messagePart), [])

  return {
    data,
    loading,
    send: sendWrapper,
  }
}
