import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { Comment, Comments, ThreadComment, ThreadComments } from '@dis/types/src/CommentsTypes'

type CommentsReducerState = {
  comments?: Comments
  threadComments?: ThreadComments
}

const initialState: CommentsReducerState = {
  comments: undefined,
  threadComments: undefined,
}

export const commentsSlice = createSlice({
  initialState,
  name: 'comments',
  reducers: {
    addComment: (state: CommentsReducerState, action: PayloadAction<Comment>) => {
      const comment = action.payload
      state.comments = {
        items: [...(state.comments?.items?.filter(({ id }) => id !== comment.id) || []), comment],
        threadOwnershipDisabled: state.comments?.threadOwnershipDisabled || false,
      }

      if (!comment.threadCommentId) {
        state.threadComments = {
          items: [
            ...(state.threadComments?.items?.filter(({ id }) => id !== comment.id) || []),
            comment,
          ],
        }
      }
    },
    addThreadComment: (state: CommentsReducerState, action: PayloadAction<ThreadComment>) => {
      const threadComment = action.payload
      state.threadComments = {
        items: [
          ...(state.threadComments?.items.filter(({ id }) => id !== threadComment.id) || []),
          threadComment,
        ],
      }
    },
    clearComments: (state: CommentsReducerState) => {
      state.comments = undefined
    },
    deleteCommentById: (state: CommentsReducerState, action: PayloadAction<string>) => {
      const commentId = action.payload
      state.comments = {
        items: [
          ...(state.comments?.items?.filter(
            ({ id, threadCommentId }) => id !== commentId && threadCommentId !== commentId,
          ) || []),
        ],
        threadOwnershipDisabled: state.comments?.threadOwnershipDisabled || false,
      }
      state.threadComments = {
        items: [...(state.threadComments?.items?.filter(({ id }) => id !== commentId) || [])],
      }
    },
    setComments: (state: CommentsReducerState, action: PayloadAction<Comments>) => {
      state.comments = action.payload
    },
    setThreadComments: (state: CommentsReducerState, action: PayloadAction<ThreadComments>) => {
      state.threadComments = action.payload
    },
    updateComment: (state: CommentsReducerState, action: PayloadAction<Comment>) => {
      const comment = action.payload
      state.comments = {
        items: [
          ...(state.comments?.items?.map((item) => (item.id !== comment.id ? item : comment)) ||
            []),
        ],
        threadOwnershipDisabled: state.comments?.threadOwnershipDisabled || false,
      }
      state.threadComments = {
        items: [
          ...(state.threadComments?.items?.map((item) =>
            item.id !== comment.id ? item : comment,
          ) || []),
        ],
      }
    },
  },
})
