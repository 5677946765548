import { useCallback, useRef, useState } from 'react'
import { WsEnhancedResponseMessage, WsMoveMessageModel } from '@dis/types/src/wsModels'
import { Api } from '@dis/api'

type Props = {
  onData?: (data: WsEnhancedResponseMessage) => void
}

export const useMove = (props?: Props) => {
  const [data, setData] = useState<WsEnhancedResponseMessage>()
  const [loading, setLoading] = useState(false)

  const onData = props?.onData

  const onMessage = useCallback(
    (newData: WsEnhancedResponseMessage) => {
      setData(newData)
      setLoading(false)
      onData?.(newData)
    },
    [onData, setData, setLoading],
  )

  const send = useCallback(
    (message: WsMoveMessageModel) => {
      setLoading(true)
      Api.sendMoveMessage(message, onMessage)
    },
    [onMessage],
  )

  const sendRef = useRef(send)
  sendRef.current = send

  const sendWrapper = useCallback((message: WsMoveMessageModel) => sendRef.current(message), [])

  return {
    data,
    loading,
    send: sendWrapper,
  }
}
